/************* CDLP Variables *************/
$primary: #70173b;
$whiteThin: rgba(255, 255, 255, .6);
$secondary: rgb(148, 81, 108);
$primaryHov: rgba(255, 255, 255, .3);
$garamond: "EB Garamond", serif;
$eurostile: "Eurostile", sans-serif;
/********** End CDLP Variables ***********/

/* eurostile-regular */
@font-face {
    font-family: "Eurostile"; 
    font-display: swap;
    src: url("/assets/files/fonts/eurostile/eurostile.woff") format("woff"); 
    font-weight: 400; font-style: normal; 
}
/* eurostile-600 */
@font-face {
    font-family: "Eurostile Bold"; 
    font-display: swap;
    src: url("/assets/files/fonts/eurostile/eurostile-bold.woff2") format("woff2"); 
    font-weight: 600; 
    font-style: normal;
}
/* eb-garamond-regular - latin */
@font-face {
    font-family: "EB Garamond"; 
    font-display: swap;
    font-style: normal; 
    font-weight: 400;
    src: url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-regular.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-regular.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-regular.woff") format("woff"), /* Modern Browsers */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-regular.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-regular.svg#EBGaramond") format("svg"); /* Legacy iOS */
}
/* eb-garamond-600 - latin */
@font-face {
    font-family: "EB Garamond"; 
    font-display: swap;
    font-style: normal; 
    font-weight: 600;
    src: url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-600.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-600.woff2") format("woff2"), /* Super Modern Browsers */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-600.woff") format("woff"), /* Modern Browsers */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-600.ttf") format("truetype"), /* Safari, Android, iOS */
    url("/assets/files/fonts/eb-garamond/eb-garamond-v13-latin-600.svg#EBGaramond") format("svg"); /* Legacy iOS */
}

/************* Librairies *************/
@import "~bootstrap/scss/bootstrap";
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
/********** End Librairies ***********/

/************* BootStrap CSS *************/
.btn{font-family: $garamond;}
.bg-dark {background-color: $primary !important;}
.btn-cdlp{background-color: #fff; border-radius: 0; border: .25rem solid #97103f; color: $primary !important; font-weight: 600; text-transform: uppercase;}
.btn-cdlp:hover{background-color: $primary; color: #fff !important; border-color: #fff;}

.btn-cdlp-dark{background-color: $primary; border-radius: 0; color: #fff;}
.btn-cdlp-dark:hover{background-color: $secondary; color: #fff;}

.btn-outline-danger {color: $primary; border-color: $primary;}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle{
    background-color: $primary;
    border-color: $primary;
    box-shadow: none;
    color: #fff;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus{
    box-shadow: none;
}
.btn-outline-danger:focus, .btn-outline-danger.focus, .btn-outline-danger:hover, .btn-outline-danger.hover{
    background-color: $primary;
    box-shadow: none;
    border-color: $primary;
    color: #fff;
}
.img-thumbnail{border-radius: 0;}
.text-bold {
    font-weight: bold;
}
/********** End BootStrap CSS ***********/

/************* HTML elements *************/
body {color: $primary; font-family: $garamond; font-size: 16px; letter-spacing: 2px;}
a{text-decoration: none;}
a:hover{text-decoration: none;}

h1, .h1 {
    font-family: $garamond;
    font-weight: bold;
    font-size: 40px;
    margin: 0 auto;
    padding-bottom: 1rem;
    text-align: center;
    text-transform: uppercase;
}
h2, .h2 {
    font-family: $garamond;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    border-bottom: 3px solid rgba(112, 23, 59, .4);
    padding-bottom: .2rem;
}
h3, .h3 {font-family: $eurostile; font-size: 22px; font-weight: bold; text-transform: uppercase;}
h4, .h4 {font-family: $eurostile; font-size: 18px; font-weight: bold; text-transform: uppercase;}

blockquote {border-left: 4px solid #dadada; margin-left: 1rem; padding-left: 1rem;}
/********** End HTML elements ***********/


.read-more-post:after, .read-more-list:after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 90%);
    width: 100%;
    height: 20rem;
    overflow: hidden !important;
}

.read-more-post:after {
    background-image: linear-gradient(to bottom, rgba(255,255,255, 0), rgba(255,255,255, 1) 70%);
}
